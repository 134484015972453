import styled from 'styled-components'

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 120px;
  overflow: hidden;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 16px;
    min-height: 100px;
  }
`

export const CardRow = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 16px;
  overflow-x: auto;
  padding-bottom: 8px;
  scrollbar-width: none;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 2000px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`

export const VaccinesPackagesWrapper = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  font-family: ${props => props.theme.typography.primary} !important;
  justify-content: flex-start;
  margin-bottom: 16px;
  min-height: 200px;
  padding: 1px 16px 1px;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 24px;
    min-height: 150px;
    padding: 1px 8px 1px;
  }
`

export const Card = styled.div`
  align-items: flex-start;
  background: #FFF;
  border-radius: 12px;
  box-shadow: 0px 4px 8px 2px rgba(183, 183, 183, 0.20);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 112px;
  padding: 12px;
  transition: all 0.2s ease;
  width: 100%;

  @media (max-width: 768px) {
    height: 100px;
  }
`

export const CardTitle = styled.p`
  color: #464646;
  font-family: Asap;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
`

export const CardValue = styled.p`
  color: #ED0F69;
  font-family: Asap;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
`

export const PackageText = styled.button<{ isSelected?: boolean }>`
  align-items: flex-start;
  align-self: stretch;
  background: none;
  border: none;
  color: ${props => props.isSelected ? '#ED0F69' : '#464646'};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: Asap;
  font-size: 16px;
  font-weight: ${props => props.isSelected ? '600' : '400'};
  padding: 8px;
  text-align: left;
  transition: all 0.2s ease;
  width: 100%;

  @media (max-width: 768px) {
    padding: 6px;
  }
`

export const VaccinesCardsText = styled.p`
  color: var(--www-fleury-com-br-tundora, #464646);
  font-family: var(--font-family-Font-1, Asap);
  font-size: var(--font-size-24, 24px);
  font-style: normal;
  font-weight: var(--font-weight-400, 400);
  line-height: var(--line-height-36, 36px);
  margin-bottom: 12px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
    margin-top: 24px;
  }
`

export const VaccinesPackagesText = styled.p`
  color: #ED0F69;
  font-family: Asap;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 27px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }
`