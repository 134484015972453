import React from 'react';

import { CosmicjsInformacoesEstaticas } from 'site/src/data/graphql/graphql-types';
import { VaccinesListPage } from './component/vaccines-list-page.component';
import { PageProps } from '../../../site/src/utils/local-types';
import { graphql } from 'gatsby'

interface VaccinesListProps {
  slug: string;
  title: string;
  value: string;
  isFeatured: string;
  category: string;
  packages: string[];
}

const VaccinesList: React.FC<PageProps<VaccinesListProps>> = (props: any) => {
  const staticData = props.data.cosmicjsInformacoesEstaticas as CosmicjsInformacoesEstaticas;
  
  return (
    <VaccinesListPage
      siteUrl={props.data.site.siteMetadata.siteUrl}
      fleuryElasticSearchUrl={props.data.site.siteMetadata.fleuryElasticSearchUrl}
      location={props.location}
      items={props.pageContext.items}
      staticData={staticData} />
  );
};

export default VaccinesList;

export const query = graphql`
  query VacinasTemplate {
    cosmicjsInformacoesEstaticas(slug: { eq: "vacinas" }) {
      metadata {
        titulo
        busca_placeholder
        titulo__resultado_nao_encontrado
        seo {
          titulo
          descricao
          imagemAlternativa
          imagem {
            url
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        fleuryElasticSearchUrl
      }
    }
  }
`