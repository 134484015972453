import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { MainTitle, SubTitle } from '../../../../site/src/components/Typography';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { theme } from '../../../../site/src/components/obj.theme';
import SvgArrowRight from 'atomic/atm.svg-icon/ArrowRight';
import SvgArrowLeft from 'atomic/atm.svg-icon/ArrowLeft';
import imgixUrlOptimizerAuto from '../../../../site/src/utils/imgixUrlOptimizerAuto';
import { getUnitDetailPageUrl, getPathUrl, appPaths } from 'utils/path';
import { isDoctorUrl } from 'utils/url';

const InfoContainer = styled.div`
  background-color: #ffffff;
  border-radius: 24px;
  padding: 4px;
  box-shadow: 4px 5px 5px -3px rgba(0, 0, 0, 0.1);
`;

const PaddingContainer = styled.div`
  padding: 12px;
`;

const Spacing = styled.div`
  margin-bottom: 24px;
`;

const Unities = styled.div`
	width: 90%;
	margin: 0 auto;
  background-color: #ffffff !important;

	@media (max-width: 1023.98px) {
		width: unset;
		margin-bottom: 10px;
	}

	@media (max-width: 1024px) {
		margin-bottom: 10px;
	}

	.slick-track {
		display: flex;
		margin: inherit;
	}
	.slick-dots {
		bottom: -20px;
		li {
			width: 11px;
			height: 11px;
			button {
				width: 11px;
				height: 11px;
				&:before {
					width: 11px;
					height: 11px;
					line-height: 11px;
					font-size: 9.4px;
					color: #e4e4e4;
					opacity: 1;
				}
			}
			&.slick-active {
				button {
					&:before {
						color: ${theme.color.primary};
					}
				}
			}
		}
	}

	.slick-slide {
		margin: 0 10px 0 0;
	}
	.unity {
		height: 328px;
		min-width: 230px;
		max-width: 231px;
		margin: 0px;
		background: #ffffff;
		box-shadow: 4px 5px 5px -3px rgba(0, 0, 0, 0.1);
		border-radius: 24px;
		margin-bottom: 16px;

		@media (max-width: 1024px) {
			min-width: 191px;
			max-width: 192px;
		}

		@media (max-width: 500px) {
			min-width: 191px;
			max-width: 192px;
		}
	}

	.unity-address {
		height: 288px;
	}

	.bottom-links {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 8px;
		color: ${theme.color.primary} !important;
	}

	img {
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		width: 100%;
		max-height: 100px;
		@media (max-width: 1024px) {
			max-height: 160px;
		}
	}
	.name {
		font-size: 16px;
		font-weight: 900;
		color: #333333;
		margin: 10px 0;
		padding: 4px;
	}
	.address {
		font-size: 14px;
		line-height: 20px;
		color: #666666;
		padding: 4px;
	}
	.slick-next {
		right: -30px;

		@media (max-width: 1023.98px) {
			display: none !important;
		}
	}
	.slick-prev {
		@media (max-width: 1023.98px) {
			display: none !important;
		}
	}
	.slick-next:before {
		display: none;
	}
	.slick-prev:before {
		display: none;
	}
	.slick-next.slick-disabled {
		opacity: 0;
	}
	.slick-prev.slick-disabled {
		opacity: 0;
	}
`;

const UnidadeMapa = styled.button`
	font-family: ${props => props.theme.typography.primary} !important;
	text-decoration: none;
	background: none;
	border: none;
	color: ${theme.color.primary};
	font-size: 14px;
	cursor: pointer;
`;

const UnidadeLink = styled(Link)`
  font-family: ${props => props.theme.typography.primary} !important;
	text-decoration: none;
	color: ${theme.color.primary};
	font-size: 14px;
`;

const UnidadesButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: -16px 8px 30px;
`;

const UnidadesButton = styled(Link)`
	font-family: ${props => props.theme.typography.primary};
	text-decoration: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	border-radius: 100px;
	width: 200px;
	height: 48px;
	border: none;
	background-color: white;
	color: ${theme.color.primary};
	border: 2px solid ${theme.color.primary};

	&:not([disabled]):hover {
		background: #f1f1f1;
		cursor: pointer;
	}
`;

const UnitiesMobileBg = styled.div`
  //max-width: fit-content;
	background: #fff;
	padding: 40px 0 40px 15px;
	margin: 10px 0 40px 0;

	@media (min-width: 1024px) {
		background: none;
		padding: 0;
		margin: 0;
	}
`;

interface VaccinesDetailContentRowProps {
    nome: string;
    descricao: string;
    unidades?: Array<{
        _id: string;
        slug: string;
        title: string;
        metadata: {
            bairro: string;
            cep: string;
            cidade: string;
            endereco: string;
            estado: string;
            idLegado: string;
            nome: string;
            fotos?: Array<{
                imagem: {
                  imgix_url: string;
                }
            }>;
        };
    }>;
    location?: Location;
}

export const VaccinesDetailContentRow: React.FC<VaccinesDetailContentRowProps> = ({
    nome,
    descricao,
    unidades,
    location
}) => {
    const [arrayUnidades, setArrayUnidades] = useState([]);
    const isDoctor = location ? isDoctorUrl(location.pathname) : false;

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <button
                className={className}
                style={{
                    ...style,
                    border: 'none',
                    backgroundColor: 'transparent',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat'
                }}
                onClick={onClick}
            >
                <SvgArrowRight height='32px' fill={theme.color.primary} />
            </button>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <button
                className={className}
                style={{
                    ...style,
                    border: 'none',
                    backgroundColor: 'transparent',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    marginLeft: '-16px'
                }}
                onClick={onClick}
            >
                <SvgArrowLeft height='32px' fill={theme.color.primary} />
            </button>
        );
    }

    const slickSettings = {
        infinite: false,
        arrows: true,
        initialSlide: 0,
        variableWidth: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        touchMove: true,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    touchMove: true,
                    swipe: true,
                    swipeToSlide: true,
                    centerMode: false,
                    infinite: false,
                    centerPadding: 50,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    touchMove: true,
                    swipe: true,
                    swipeToSlide: true,
                    centerMode: false,
                    infinite: false,
                    centerPadding: 50,
                }
            }
        ]
    };

    useEffect(() => {
        console.log(unidades);
        if (unidades?.length > 0) {
            setArrayUnidades(
                unidades.map(unit => (
                    <div className='unity' key={`vaccines-unities-${unit.slug}`}>
                        <div className='unity-address'>
                            {unit.metadata.fotos && unit.metadata.fotos[0].imagem.imgix_url && (
                                <img src={imgixUrlOptimizerAuto(unit.metadata.fotos[0].imagem.imgix_url, 312, 204)} alt='Unidade' />
                            )}
                            {(!unit.metadata.fotos || !unit.metadata.fotos[0].imagem.imgix_url) && <p>Imagem não encontrada</p>}
                            <p className='name'>{unit.title}</p>
                            <p className='address'>
                                {unit.metadata.endereco} - {unit.metadata.bairro} - {unit.metadata.estado} - {unit.metadata.cep}
                            </p>
                        </div>
                        <div className='bottom-links'>
                            <UnidadeMapa
                                onClick={() => {
                                    window.open(
                                        `https://maps.google.com/maps?q=${encodeURIComponent(
                                            unit.metadata.nome + unit.metadata.endereco + unit.metadata.bairro + unit.metadata.cidade
                                        )}+(${encodeURIComponent(unit.metadata.nome)})&ie=UTF8`
                                    )
                                }}
                            >
                                Ver no mapa
                            </UnidadeMapa>
                            <UnidadeLink
                                id={`vaccine-unit-${unit.metadata.nome}`}
                                to={getUnitDetailPageUrl(getPathUrl(appPaths.units.path, isDoctor), unit.slug)}
                            >
                                Abrir detalhes
                            </UnidadeLink>
                        </div>
                    </div>
                ))
            );
        } else {
            setArrayUnidades([]);
        }
    }, [unidades]);

    return (
        <Grid container alignItems="flex-start" justifyContent="space-between" spacing={4}>
            <Grid item xs={12} sm={12} lg={6}>
                <InfoContainer>
                    <PaddingContainer>
                        <MainTitle>{nome}</MainTitle>
                        <Spacing />
                        <Typography
                            color="textPrimary"
                            component="div"
                            dangerouslySetInnerHTML={{ __html: descricao }}
                        />
                    </PaddingContainer>
                </InfoContainer>
            </Grid>

            {arrayUnidades?.length > 0 && (
                <Grid item xs={12} sm={12} lg={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <MainTitle variant="h2">
                                {`${unidades?.length}
                  ${unidades?.length > 1 ? 'unidades oferecem ' : 'unidade oferece '}
                    essa vacina`}
                            </MainTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <UnitiesMobileBg>
                                <Unities>
                                    <Slider {...slickSettings}>
                                        {arrayUnidades}
                                    </Slider>
                                </Unities>
                            </UnitiesMobileBg>
                        </Grid>
                    </Grid>

                    <UnidadesButtonContainer>
                        <UnidadesButton to={'/unidades/'} target="_blank">
                            Ver todas as unidades
                        </UnidadesButton>
                    </UnidadesButtonContainer>
                </Grid>
            )}
        </Grid>
    );
};
