import React from 'react';

import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component';
import { VaccinesDetailContentRow } from './component/vaccines-detail-content-row.component';
import { ClientDoctorSelectContext } from '../../../site/src/context/client-doctor-select'
import { SEO } from '../../../site/src/components/legacy/mol.seo/seo.component'
import IndexLayout from 'site/src/components/org.layout/layout.component';
import { PageProps } from '../../../site/src/utils/local-types'
import { Query } from 'site/src/data/graphql/graphql-types';
import { MainTitle } from 'site/src/components/Typography';
import { appPaths, getCanonicalUrl } from 'utils/path';
import { graphql } from 'gatsby'
import { Grid } from 'atomic';

interface VaccinesDetailProps {
  slug: string;
  title: string;
  metadata?: {
    idLegado: string;
    nome: string;
    sobre: string;
    unidade?: Array<{
      _id: string;
      slug: string;
      title: string;
      metadata: {
        bairro: string;
        cep: string;
        cidade: string;
        endereco: string;
        estado: string;
        idLegado: string;
        nome: string;
        fotos?: Array<{
          imagem: {
            imgix_url: string;
          }
        }>;
      };
    }>;
  };
}

const VaccinesDetail: React.FC<PageProps<VaccinesDetailProps, Query>> = (props: any) => {
  const vaccine = props.data.allCosmicjsVacinas.nodes[0];
  const breadcrumbAdditionalDictionary = {}
  breadcrumbAdditionalDictionary[`/${props.pageContext.slug}`] = vaccine.title;

  if (!vaccine) {
    return null;
  }

  return (
    <ClientDoctorSelectContext.Provider
      value={{
        clientUrl: `/vacinas/${props.pageContext.slug}`,
        doctorUrl: `/vacinas/${props.pageContext.slug}`
      }}
    >
      <IndexLayout location={props.location}>
        <SEO
          socialMedia={{
            canonicalUrl: getCanonicalUrl(
              props.data.site.siteMetadata.siteUrl,
              appPaths.vaccines.path,
              props.pageContext.slug
            ),
            title: vaccine.metadata.nome,
            description: vaccine.metadata.sobre
          }}
        />
        <Grid style={{ padding: "12px" }}>
          <TitleWithBreadcrumbRow addtionalDictionary={breadcrumbAdditionalDictionary} />
          <MainTitle>Vacinas</MainTitle>
          <VaccinesDetailContentRow
            nome={vaccine.title}
            descricao={vaccine.content}
            unidades={vaccine.metadata.unidade}
            location={props.location}
          />
        </Grid>
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  );
};

export default VaccinesDetail;

export const query = graphql`
  query VaccineDetailBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allCosmicjsVacinas(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        metadata {
          idLegado
          nome
          sobre
          unidade {
            _id
            title
            slug
            metadata {
              bairro
              cep
              cidade
              endereco
              estado
              idLegado
              nome
              fotos {
                imagem {
                  url
                  imgix_url
                }
              }
            }
          }
        }
      }
    }
  }
`
