import { Col, Grid, Row } from 'atomic'
import { useMediaQuery, useTheme } from '@material-ui/core'
import * as React from 'react'
import TitleWithBreadcrumbRow from '../org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { MainTitle } from '@root/src/components/Typography'
import { VaccinesPackagesWrapper, VaccinesPackagesText, PackageText, CardsContainer, CardRow, Card, VaccinesCardsText, CardTitle, CardValue } from './text-list-vaccines-page.style'
import { navigate } from 'gatsby'

interface TextListPageProps {
  location: Location
  pageData: TextListPageData
  vaccinesClassification: any
  setVaccinesClassification: any
  handleClickFilter: any
  loading?: boolean
  featuredVaccines?: {
    titulo: string
    slug: string
    value: string
    isFeatured: Boolean;
    packages: string[];
    classification?: string;
  }[]

  children: JSX.Element | JSX.Element[]
}

export interface TextListItem {
  titulo: string
  slug: string
  value: string
  isFeatured: string
  packages: string[]
  synonyms: string[]
  category: string
}

export interface TextListPageData {
  title: string
  searchPlaceholder: string
  selectPlaceholder: string
  getItems: (startIndex: number, endIndex: number) => TextListItem[]
  itemsCount: number
  showItemCount: boolean
  col2Title: string
  col2ContentHtml: string
}

export const TextListHeader: React.FunctionComponent<any> = props => <>{props.children}</>
TextListHeader.displayName = 'TextListHeader'

export const TextListFooter: React.FunctionComponent<any> = props => <>{props.children}</>
TextListFooter.displayName = 'TextListFooter'

export const TextListPage: React.FunctionComponent<TextListPageProps> = props => {
  const pageData = props.pageData
  const featuredVaccines = props.featuredVaccines
  const theme = useTheme()
  const downOfMd = useMediaQuery(theme.breakpoints.down('sm'))

  const filteredAndFeaturedVaccines = (featuredVaccines || [])?.filter((item) => item.isFeatured)
  
  const vaccinesByCategory = filteredAndFeaturedVaccines.reduce((acc, vaccine) => {
    const category = vaccine.category || 'Sem Categoria'
    if (!acc[category]) {
      acc[category] = []
    }
    acc[category].push(vaccine)
    return acc
  }, {} as Record<string, typeof filteredAndFeaturedVaccines>)

  const adjustMarginTop = downOfMd ?
    process.env.GATSBY_COSMIC_BUCKET?.toLowerCase() === 'weinmann' ? 100 : 0 : 0

  const header = React.Children.map(props.children, (child: any) => {
    if (child && child.type.displayName === TextListHeader.displayName) {
      return child
    }
    return ''
  })
  const footer = React.Children.map(props.children, (child: any) => {
    if (child && child.type.displayName === TextListFooter.displayName) {
      return child
    }
    return ''
  })

  return (
    <Grid style={{ marginTop: adjustMarginTop }} maxWidth="md">
      <TitleWithBreadcrumbRow />
      <Row style={{ marginLeft: "2px", marginTop: "-24px", marginBottom: "16px" }} >
        <MainTitle variant='h2'>{pageData.title}</MainTitle>
      </Row>
      {header}
      <Row mb>
        <Col md={10}>
          {Object.keys(vaccinesByCategory).length === 0 ? (
            <VaccinesPackagesWrapper>
              <span style={{
                display: 'block',
                fontSize: '16px',
                color: '#666',
                fontWeight: 'normal',
                marginTop: '8px',
                textAlign: 'center',
                padding: '32px'
              }}>
                Nenhum resultado foi encontrado
              </span>
            </VaccinesPackagesWrapper>
          ) : (
            Object.entries(vaccinesByCategory).map(([category, vaccines]) => (
              <VaccinesPackagesWrapper key={category}>
                <VaccinesCardsText style={{ textAlign: 'left' }}>
                  {category}
                </VaccinesCardsText>
                <CardsContainer>
                  <CardRow>
                    {vaccines.map((item, index) => (
                      <Card
                        key={index}
                        onClick={() => navigate(`/vacinas/${item.slug}`)}
                      >
                        <CardTitle>
                          {item.titulo}
                        </CardTitle>
                        <CardValue>
                          R$ {item.value}
                        </CardValue>
                      </Card>
                    ))}
                  </CardRow>
                </CardsContainer>
              </VaccinesPackagesWrapper>
            ))
          )}
        </Col>
        <Col md={2}>
          <VaccinesPackagesWrapper>
            <VaccinesPackagesText>Pacotes</VaccinesPackagesText>
            {props.vaccinesClassification?.map((item, index) => (
              <PackageText
                key={item.classification}
                onClick={() => props.handleClickFilter(index)}
                isSelected={item.selected}
              >
                {item.classification}
              </PackageText>
            ))}
          </VaccinesPackagesWrapper>
        </Col>
        <Col xs={12} md={8}>
          {footer}
        </Col>
      </Row>
    </Grid>
  )
}
