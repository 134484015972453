import styled from 'styled-components'
import { theme } from '../../obj.theme'

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: ${theme.typography.primary} !important;
  width: 101.5%;
  border-radius: 45px;
  border: solid 2px ${theme.color.primary};
  background-color: #FFF;
  color: ${theme.color.primary};  
  padding: 8px 6px 8px 20px;
  height: fit-content;
  margin-top: 16px;
  margin-bottom: 32px;
`

export const TextField = styled.input`
  width: 100%;  
  color: ${theme.color.primary} !important;
  font-family: ${theme.typography.primary} !important;
  font-size: 16px;
  border: 0 none;
  outline: 0;
    
  ::placeholder {    
    color: ${theme.color.primary} !important;
    opacity: 0.7;
  }  
` 