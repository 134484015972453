import { FieldsWrapper } from '@root/../site/src/components/atm.wrapper/wrapper.component'
import { DebouncedSearchVaccines } from '@root/../site/src/components/legacy/mol.search/debounced-search-vaccines.component'
import { SearchQueryParam } from '@root/../site/src/components/legacy/mol.search/debounced-search.utils'
import { FetchContainer } from '@root/../site/src/components/legacy/obj.http-container/fetch-container.component'
import { getPaginationQueryParam } from '@root/../site/src/components/legacy/obj.pagination-container/pagination-query-param-getter'
import {
    TextListHeader,
    TextListItem,
    TextListPage,
    TextListPageData
} from '@root/../site/src/components/org.text-list-vaccines-page/text-list-vaccines-page.component'
import { ClientDoctorSelectContext } from '@root/../site/src/context/client-doctor-select'
import {
    VaccineSearchResponse,
    mapToVaccineSearchResponse
} from '@root/../site/src/data/http/dto/vaccine-elastic-search.response'
import flashDispatcherService from '@root/../site/src/components/legacy/obj.flash-wrapper/flash-dispatcher.service'
import IndexLayout from '@root/../site/src/components/org.layout/layout.component'
import {
    CosmicjsInformacoesEstaticas,
    CosmicjsInformacoesEstaticasMetadataSeo
} from '@root/../site/src/data/graphql/graphql-types'
import { buildVaccineElasticSearchRequest } from '@root/../site/src/data/http/request-builder/vaccine-elastic-search.request'
import { Col, Row } from 'atomic'
import { navigate } from 'gatsby'
import * as queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { hasWindow } from 'utils/browser'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { getClientAndDoctorUrl } from 'utils/url'
import sanitizeRegex from '@root/../site/src/utils/sanitize-regex-elastic'
import axios from '@root/../site/src/utils/base-axios.js'

export interface VaccinesListPageProps {
    items: TextListItem[]
    staticData: CosmicjsInformacoesEstaticas
    siteUrl: string
    fleuryElasticSearchUrl: string
    location: Location
}

export const VaccinesListPage: React.FunctionComponent<VaccinesListPageProps> = props => {
    const currentQueryParam: Partial<SearchQueryParam> = queryString.parse(props.location.search)
    const busca = currentQueryParam.busca || ''
    const [changedFilter, setChangedFilter] = useState(false)
    const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)
    const [countVaccinesResults, setCountVaccinesResults] = useState<any>()
    const featuredVaccines = props.items
    const pageData: Partial<TextListPageData> = getPartialPageData(
        props.staticData,
        props.siteUrl,
        props.staticData.metadata.seo
    )
    const params = getPaginationQueryParam(currentQueryParam)
    const [queryFilter, setQueryFilter] = useState([])
    const [responseElastic, setResponseElastic] = useState<VaccineSearchResponse>()
    const searchModeActive = false
    const [vaccinesClassification, setVaccinesClassification] = useState([
        {
            classification: 'Crianças',
            initials: 'CD',
            quantity: 0,
            selected: false
        },
        {
            classification: 'Destaques',
            initials: 'AC',
            quantity: 0,
            selected: false
        },
        {
            classification: 'Homens',
            initials: 'CH',
            quantity: 0,
            selected: false
        },
        {
            classification: 'Idosos',
            initials: 'CJ',
            quantity: 0,
            selected: false
        },
        {
            classification: 'Mulheres',
            initials: 'CI',
            quantity: 0,
            selected: false
        }
    ])

    const fetchData = async () => {
        try {
            const data = await axios.post('/elasticsearch/digital/free-search', {
                search: {
                    query: {
                        bool: {
                            must: [
                                {
                                    query_string: {
                                        query: `*${busca.replace(sanitizeRegex, '')}*`,
                                        fields: ['name', 'synonyms', 'slug', 'productInitials']
                                    }
                                }
                            ],
                            must_not: [
                                {
                                    term: {
                                        id: 9056
                                    }
                                },
                                {
                                    term: {
                                        id: 9057
                                    }
                                },
                                {
                                    term: {
                                        id: 9058
                                    }
                                }
                            ]
                        }
                    },
                    from: (params.page - 1) * params.limit,
                    size: params.limit
                },
                index: `${process.env.GATSBY_COSMIC_BUCKET || ''}-exames`
            })

            const response = mapToVaccineSearchResponse(data)
            setResponseElastic(response)

            const unfilteredQuery = {
                query: {
                    bool: {
                        must: {
                            query_string: {
                                query: `*${busca.replace(sanitizeRegex, '')}*`,
                                fields: ['name', 'synonyms', 'slug', 'productInitials']
                            }
                        }
                    }
                }
            }

            const countClassification = await axios.post('/elasticsearch/digital/free-search', {
                search: {
                    ...unfilteredQuery,
                    size: 0,
                    aggs: {
                        classification_count_ac: {
                            filter: {
                                term: {
                                    'classification.initials.keyword': 'AC'
                                }
                            },
                            aggs: {
                                count: {
                                    value_count: {
                                        field: 'product.initials.keyword'
                                    }
                                }
                            }
                        },
                        classification_count_cd: {
                            filter: {
                                term: {
                                    'classification.initials.keyword': 'CD'
                                }
                            },
                            aggs: {
                                count: {
                                    value_count: {
                                        field: 'product.initials.keyword'
                                    }
                                }
                            }
                        },
                        classification_count_cj: {
                            filter: {
                                term: {
                                    'classification.initials.keyword': 'CJ'
                                }
                            },
                            aggs: {
                                count: {
                                    value_count: {
                                        field: 'product.initials.keyword'
                                    }
                                }
                            }
                        }
                    }
                },
                index: `${process.env.GATSBY_COSMIC_BUCKET || ''}-exames`
            })

            setCountVaccinesResults(countClassification.data.aggregations)
        } catch {
            console.error('Error na requisicao do elastic para vacinas')
        }
    }

    const handleClickFilter = position => {
        setVaccinesClassification(prevArray => {
            const newArray = prevArray.map((item, index) => {
                if (index === position && item.selected) {
                    return { ...item, selected: false }
                }
                return { 
                    ...item, 
                    selected: index === position
                }
            })
            return newArray
        })
    }

    const handleValueChange = value => {
        const nextQueryParam: Partial<SearchQueryParam> = {
            ...currentQueryParam,
            busca: value,
            page: 1
        }
        const pathname = hasWindow() ? window.location.pathname : ''
        navigate(`${pathname}/?${queryString.stringify(nextQueryParam)}`)
    }

    const onError = React.useCallback(() => {
        flashDispatcherService.dispatchMessage('Ocorreu um erro em sua busca.', 'alert')
    }, [])

    useEffect(() => {
        if (countVaccinesResults) {
            setVaccinesClassification(prevArray =>
                prevArray.map(item => {
                    if (item.initials === 'AC') {
                        if (item.selected && countVaccinesResults?.classification_count_ac?.doc_count == 0) {
                            handleClickFilter(0)
                        }
                        return {
                            ...item,
                            quantity: countVaccinesResults?.classification_count_ac?.doc_count
                        }
                    } else if (item.initials === 'CD') {
                        if (item.selected && countVaccinesResults?.classification_count_cd?.doc_count == 0) {
                            handleClickFilter(1)
                        }
                        return {
                            ...item,
                            quantity: countVaccinesResults?.classification_count_cd?.doc_count
                        }
                    } else if (item.initials === 'CJ') {
                        if (item.selected && countVaccinesResults?.classification_count_cj?.doc_count == 0) {
                            handleClickFilter(2)
                        }
                        return {
                            ...item,
                            quantity: countVaccinesResults?.classification_count_cj?.doc_count
                        }
                    } else {
                        return item
                    }
                })
            )
        }
    }, [countVaccinesResults])

    useEffect(() => {
        fetchData()
    }, [params.page, params.limit])

    useEffect(() => {
        fetchData()
    }, [busca])

    useEffect(() => {
        handleValueChange('')
    }, [])

    return (
        <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
            <IndexLayout location={props.location}>
                <FetchContainer
                    input={searchModeActive ? buildVaccineElasticSearchRequest(busca, params.page, params.limit, queryFilter) : null}
                    mapper={mapToVaccineSearchResponse}
                    defaultBaseUrl={props.fleuryElasticSearchUrl}
                    onError={onError}
                >
                    {state => {
                        const selectedClassification = vaccinesClassification.find(item => item.selected)?.classification || ''
                        const itemsToShow = selectedClassification
                            ? featuredVaccines.filter(vaccine => vaccine.packages?.includes(selectedClassification))
                            : featuredVaccines

                        if (responseElastic) {
                            pageData.getItems = () => itemsToShow
                            pageData.itemsCount = itemsToShow.length
                        } else {
                            pageData.getItems = () => [{ title: '', slug: '', synonyms: [] }]
                            pageData.itemsCount = 0
                        }
                        pageData.showItemCount = pageData.itemsCount > 0

                        return (
                            <TextListPage
                                loading={state.loading}
                                pageData={pageData as TextListPageData}
                                location={props.location}
                                featuredVaccines={itemsToShow}
                                vaccinesClassification={vaccinesClassification}
                                setVaccinesClassification={setVaccinesClassification}
                                handleClickFilter={handleClickFilter}
                            >
                                <TextListHeader>
                                    <Row mb>
                                        <Col xs={12} md={12}>
                                            <FieldsWrapper>
                                                <DebouncedSearchVaccines
                                                    placeholder={'Buscar vacinas'}
                                                    loading={state.loading}
                                                    onValueChange={handleValueChange}
                                                    onSubmit={handleValueChange}
                                                    id="input-vaccine-search"
                                                />
                                            </FieldsWrapper>
                                        </Col>
                                    </Row>
                                </TextListHeader>
                            </TextListPage>
                        )
                    }}
                </FetchContainer>
            </IndexLayout>
        </ClientDoctorSelectContext.Provider>
    )
}

const getPartialPageData = (
    staticData: CosmicjsInformacoesEstaticas,
    siteUrl: string,
    seoData: CosmicjsInformacoesEstaticasMetadataSeo
): Partial<TextListPageData> => {

    return {
        title: staticData.metadata.titulo,
        searchPlaceholder: staticData.metadata.buscaPlaceholder,
        col2Title: staticData.metadata.col2Titulo,
        col2ContentHtml: staticData.metadata.col2Conteudo,
        selectPlaceholder: 'Mais buscados',
        seo: {
            siteUrl,
            canonicalUrl: getCanonicalUrl(siteUrl, appPaths.vaccines.path),
            title: seoData.titulo,
            description: seoData.descricao,
            imageUrl: seoData.imagem.url,
            imageAlt: seoData.imagemAlternativa
        }
    }
}
